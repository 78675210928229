import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './redux/store';

import {prerenderedLazy} from "./components/loadable/loadable.component"

import './i18next';

//import * as serviceWorker from './serviceWorker';
const rootElement = document.getElementById("root");

const PrerenderedApp = prerenderedLazy(() => import('./App'));

// const toRender = () => (

// );

// var loaded = true;
// if( process.env.NODE_ENV === 'development') {
//     loaded = false;
// }

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <Suspense fallback={(<div></div>)}>
            <PrerenderedApp loaded={true}/>
          </Suspense>
        </PersistGate>
      </BrowserRouter>
    </Provider>
    , rootElement);
} else {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <Suspense fallback={(<div></div>)}>
            <PrerenderedApp loaded={false}/>
          </Suspense>
        </PersistGate>
      </BrowserRouter>
    </Provider>
    , rootElement);
}